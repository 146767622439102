import React from 'react'

const DomainesText = ({ textLines, position}) => {
	return (
		<div className={`text-domaines-wrapper-${position}`}>
			{textLines.map((line) => {
				return <p>&gt; {line}</p>
			})}
		</div>
	)
}

export default DomainesText