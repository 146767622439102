import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import ImageLayout from "../components/image-layout"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import DomainesText from "../components/domaines-text"

const DomainesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background/bg-port-bendor.png" }) {
				childImageSharp {
					fluid(maxWidth: 1920) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Domaines" />
      <ImageLayout text="Domaines D'intervention" image={data.background.childImageSharp.fluid} />
      <section className="bg-grey">
        <Grid container>
          <Grid item xs={12} md={6} className="grid-item-domaines">
            <Card
              title="Droit Public" 
              text=""
              position="left" />
              <DomainesText
                position="left"
                textLines={[
                  "Marchés publics (marchés de travaux, de services et de PI)",
                  "Contrats publics (DSP, concessions, PPP)",
                  "Travaux publics (contrats, dommages)",
                  "Droit des collectivités locales",
                  "Droit administratif général (responsabilité, services publics)",
                  "Droit européen des marchés",
                  "Libertés publiques",
                  "Droit pénal des élus",
                  "Contentieux électoraux",
                  "Fonction publique d’Etat, territoriale et hospitalière",
                  "Finances publiques et fiscalité",
                  "Droit des étrangers",
                  "Permis de conduire (contentieux administratif et contentieux pénal)",
              ]} />
          </Grid>
          <Grid item xs={12} md={6} className="grid-item-domaines">
          <Card
              title="Droit de l'urbanisme et de l'environnement" 
              text=""
              position="right" />
              <DomainesText
                position="right"
                textLines={[
                  "Autorisations d’urbanisme (permis de construire, déclaration préalable, permis d’aménager)",
                  "Urbanisme réglementaire et opérationnel (POS, PLU, SCOT, SDC, Cartes communales, ZAC)",
                  "Loi littorale et domaine public maritime",
                  "Loi montagne (UTN)",
                  "Domanialité publique (concessions, AOT, baux emphytéotiques administratifs, conventions domaniales, domaine public portuaire, voirie)",
                  "Droit des ICPE (déchets, carrières, stations d’épuration)",
                  "Loi sur l’eau",
                  "Dommages environnementaux",
                  "Droit de la conception architecturale",
                  "Droit de la préemption et de l’expropriation (phase administrative/phase judiciaire)",
                  "Contentieux pénal de l’urbanisme (assistance en phase de régularisation, assistance et représentation devant les juridictions pénales, constitution de partie civile)",
              ]} />
          </Grid>
          <Grid item xs={12} md={6} className="grid-item-domaines">
          <Card
              title="Propriete intellectuelle" 
              text=""
              position="left" />
              <DomainesText
                position="left"
                textLines={[
                  "Droit d’auteur",
                  "Naming",
                  "Droit de l’architecte",
                  "Droit du photographe",
                  "Sécurisation et gestion des droits",
                  "Rédaction de contrats d’exploitation et de cession de droits d’auteur",
                  "Protection des droits d’auteur, rédaction et négociation de contrats",
                  "Démarches auprès d’institutions et organismes",
                  "Conseil et contentieux",
              ]} />
          </Grid>
          <Grid item xs={12} md={6} className="grid-item-domaines">
            <Card
              title="Droit International et Européen" 
              text=""
              position="right" />
              <DomainesText
                position="right"
                textLines={[
                  "Droit des organisations internationales (contentieux de l’immunité de juridiction et d’exécution, contrats, fonction publique internationale)",
                  "Droit des investissements internationaux (sécurisation des investissements français à l’étranger, négociations et contrats)",
                  "Droit européen (droit matériel et processuel)",
              ]} />
          </Grid>
        </Grid>
      </section>
    </Layout>
  )
}

export default DomainesPage
